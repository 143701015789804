.leavingHomeContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh; /* Adjusted height for full viewport */
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leavingHomeImage {
  width: 160%;
  height: 110%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 10s;
}

.leavingHomeButton {
  position: absolute;
  top: 25%;
  left: 25%;
  width: 200px;
  padding: 10px;
  background-color: #ff5733;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  opacity: 0;
  transition: opacity 10s;
}
