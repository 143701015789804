.container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' width='80' height='80'%3E%3Cpath fill='%239C92AC' fill-opacity='0.4' d='M14 16H9v-2h5V9.87a4 4 0 1 1 2 0V14h5v2h-5v15.95A10 10 0 0 0 23.66 27l-3.46-2 8.2-2.2-2.9 5a12 12 0 0 1-21 0l-2.89-5 8.2 2.2-3.47 2A10 10 0 0 0 14 31.95V16zm40 40h-5v-2h5v-4.13a4 4 0 1 1 2 0V54h5v2h-5v15.95A10 10 0 0 0 63.66 67l-3.47-2 8.2-2.2-2.88 5a12 12 0 0 1-21.02 0l-2.88-5 8.2 2.2-3.47 2A10 10 0 0 0 54 71.95V56zm-39 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm40-40a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM15 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm40 40a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'%3E%3C/path%3E%3C/svg%3E");
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 2s;
  transform: scale(1.3);
}

.container.fade {
  background-color: rgba(0, 0, 0, 1);
}

.centerContainer {
  position: absolute;
  top: 45%; /* Slightly above center */
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  border: none;
  background: none;
  padding: 0;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.centerContainer.drift {
  animation: drift 4s infinite;
}

@keyframes drift {
  0% {
    transform: translate(-50%, -50%) translateX(0%) translateY(0%);
  }
  25% {
    transform: translate(-50%, -50%) translateX(-2%) translateY(0%);
  }
  50% {
    transform: translate(-50%, -50%) translateX(0%) translateY(2%);
  }
  75% {
    transform: translate(-50%, -50%) translateX(2%) translateY(0%);
  }
  100% {
    transform: translate(-50%, -50%) translateX(0%) translateY(-2%);
  }
}
