.knotsTrackerContainer {
  position: absolute;
  top: 23vh;
  right: 25vw;
  background: rgba(255, 255, 255, 0.7);
  padding: 10px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.helmImage {
  width: 50px;
  height: 50px;
}
