.seamusContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.seamusImage {
  width: 150px;
  height: 100px;
  margin-bottom: -25px;
  z-index: 1000;
}

.seamusRaftImage {
  width: 250px;
  height: 80px;
}
