.flashContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-color: #111;
}

.flashImageContainer {
  width: 100%;
  height: 50%; /* Start height at 0% */
  overflow: hidden; /* Ensure the image is clipped */
  transition: height 2.5s; /* Initial transition for growing height */
}

.flashImageContainer.expand {
  height: 150%; /* Expand height to 300% */
  transition: height 2s; /* Duration of expansion */
}

.flashImageContainer.shrink {
  height: 0%; /* Shrink back to 0% */
  transition: height 2s ease-in-out; /* Duration of shrinking */
}

.flashImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
