.floatingBovineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.cowImage {
  width: 150px;
  height: 100px;
}

.raftImage {
  width: 250px;
  height: 80px;
}
